import request from '@/utils/request'

//获取课程/实验预习附件列表
export function getattachmentlist(params) {
    return request({
      url: '/portal/attachment/get-list',
      method: 'GET',
      params
    })
  }

// 获取预习资料进度
export function operateAttachment(params) {
  return request({
    url: '/portal/course-lib-preview/operate-attachment',
    method: 'POSt',
    params
  })
}

